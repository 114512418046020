exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-awp-js": () => import("./../../../src/pages/about-awp.js" /* webpackChunkName: "component---src-pages-about-awp-js" */),
  "component---src-pages-candidates-js": () => import("./../../../src/pages/candidates.js" /* webpackChunkName: "component---src-pages-candidates-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-isb-manifesto-js": () => import("./../../../src/pages/isb-manifesto.js" /* webpackChunkName: "component---src-pages-isb-manifesto-js" */),
  "component---src-pages-manifesto-en-js": () => import("./../../../src/pages/manifesto-en.js" /* webpackChunkName: "component---src-pages-manifesto-en-js" */),
  "component---src-pages-manifesto-ur-js": () => import("./../../../src/pages/manifesto-ur.js" /* webpackChunkName: "component---src-pages-manifesto-ur-js" */),
  "component---src-pages-support-us-js": () => import("./../../../src/pages/support-us.js" /* webpackChunkName: "component---src-pages-support-us-js" */),
  "component---src-templates-candidate-js": () => import("./../../../src/templates/candidate.js" /* webpackChunkName: "component---src-templates-candidate-js" */)
}

